<template>
  <v-navigation-drawer v-model="localDrawer" app temporary>
    <v-btn link text class="ma-3" color="white" :to="{ path: '/' }">
      <v-img
        max-width="100"
        contain
        :src="require('@/assets/dekra-arbeit-gruppe-logo.png')"
        alt="Dekra"
      />
    </v-btn>
    <v-list class="py-0 dekra-list">
      <template v-for="item in items">
        <v-list-item
          :key="item.title"
          :color="item.color"
          :to="{ path: item.path }"
        >
          <v-list-item-content>
            <v-list-item-title class="body-2 text-left">
              {{ item.title }}
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </template>
      <v-list-item
        color="green darken-3"
        href="https://www.dekra-arbeit.de/en/imprint.html"
        exact
        link
        target="_blank"
      >
        <v-list-item-content>
          <v-list-item-title class="body-2 text-left">
            Impressum
          </v-list-item-title>
        </v-list-item-content>
      </v-list-item>
    </v-list>
  </v-navigation-drawer>
</template>
<script>
export default {
  data() {
    return {
      items: [
        {
          title: "MVS",
          class: "green--text text--darken-3",
          color: "green darken-3",
          path: "/master-vendor-solution"
        },
        {
          title: "Dekra Arbeit",
          class: "green--text text--darken-3",
          color: "green darken-3",
          path: "/dekra-arbeit"
        },
        {
          title: "Sign up",
          class: "green--text text--darken-3",
          color: "green darken-3",
          path: "/sign-up"
        },
        {
          title: "Sign in",
          class: "green--text text--darken-3",
          color: "green darken-3",
          path: "/"
        }
      ]
    };
  },
  created() {},
  props: {
    drawer: {
      type: Boolean,
      required: true
    }
  },
  computed: {
    localDrawer: {
      get() {
        return this.drawer;
      },
      set(value) {
        this.$emit("drawer-update", value);
      }
    }
  },
  methods: {}
};
</script>
